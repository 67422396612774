import { useIntl } from "react-intl";

import { Card } from "components/ui/Card";

const DashboardOverview = () => {
  const { formatMessage: f } = useIntl();

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {f({ id: "summary.name" })}
      </h3>
      <div className="mt-5 bg-white grid grid-cols-2 md:grid-cols-3 gap-5">
        <Card
          title="Organisation"
          href="/org"
          className="bg-coral-400 hover:bg-coral-300 text-white"
        />
        <Card
          title="Know-How Träger finden"
          className="bg-gray-100 hover:bg-gray-50 text-gray-900"
          href="/"
          src="/images/pexels-photo-3854816.webp"
        />
        <Card
          title="Teamkompetenzen"
          className="bg-daintree-950 hover:bg-daintree-800 text-white"
          href="/teams"
          src="/images/pexels-photo-3184430.webp"
        />
        <Card
          title="Recruiting"
          className="bg-gray-100 hover:bg-gray-50 text-gray-900"
          href="/recruiting"
          src="/images/pexels-photo-5989934.webp"
        />
        <Card
          title="Seminare"
          className="bg-daintree-500 hover:bg-daintree-400 text-white"
          href="/seminars"
        />
        <Card
          title="Kompetenzen"
          className="bg-coral-400 hover:bg-coral-300 text-white"
          href="/skills"
        />
      </div>

      <div className="flex gap-10 m-5">
        <div className="w-1/3">
          <div className="font-bold text-lg pb-1">Dein perfekter Job</div>
          <ul className="list-none text-sm">
            <li>Matching & Vergleichen</li>
            <li>Kompetenzen finden</li>
            <li>Kompetenzlücken erkennen</li>
            <li>Seminare finden</li>
            <li>Kompetenzmappe</li>
          </ul>
        </div>
        <div className="w-1/3">
          <div className="font-bold text-lg pb-1">
            Die perfekte Wissensorganisation
          </div>
          <ul className="list-none text-sm">
            <li>Matching & Vergleichen</li>
            <li>Kompetenzen finden</li>
            <li>Simulieren & Visulisieren</li>
            <li>KPI&apos;s zum Performancemanagement</li>
          </ul>
        </div>
        <div className="w-1/3">
          <div className="font-bold text-lg pb-1">
            Das perfekte Team erstellen
          </div>
          <ul className="list-none text-sm">
            <li>Teamkompetenzen erstellen</li>
            <li>Simulieren & Visulisieren</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DashboardOverview;
