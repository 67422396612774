import { useQuery } from "@apollo/client";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  AdjustmentsIcon,
  AtSymbolIcon,
  BookOpenIcon,
  CashIcon,
  ChevronDownIcon,
  CollectionIcon,
  CubeIcon,
  DocumentReportIcon,
  IdentificationIcon,
  OfficeBuildingIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { ShareIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

// import useOnClickOutside from "hooks/useOnClickOutside";
import { NavLink } from "components/ui/permissions";
import { IS_LOGGED_IN, ROLE } from "schema/auth";

export interface NavItem {
  navItem: string;
  navUrl: string;
  roles?: string[];
  main?: boolean;
  icon?: JSX.Element;
}

interface Props {
  navItems: Array<NavItem>;
}

const Nav = ({ navItems }: Props) => {
  const { data: dataLoggedIn } = useQuery(IS_LOGGED_IN);
  const { data: dataRole } = useQuery(ROLE);

  const { formatMessage: f } = useIntl();

  const container = useRef(null);
  const popoverPanel = useRef(null);
  const popoverButton = useRef(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const location = useLocation();

  const allowedNavItems = navItems.filter((navItem) =>
    navItem.roles?.includes(dataRole?.role)
  );

  useEffect(() => {
    // https://github.com/GraphCMS/reference-marketing-website/blob/31e0d784daec60272e5a7c5c511c58edc9e63f5b/components/navigation.js
    const handleOutsideClick = (event: any) => {
      // @ts-ignore
      if (!popoverPanel?.current?.contains(event.target)) {
        setPopoverOpen(false);
      }
      // @ts-ignore
      if (popoverButton?.current?.contains(event.target)) {
        setPopoverOpen(true);
      }

      // @ts-ignore
      if (!container?.current?.contains(event.target)) {
        if (!mobileNavOpen) return;
        setMobileNavOpen(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);

    return () => window.removeEventListener("click", handleOutsideClick);
  }, [mobileNavOpen, container, popoverPanel, popoverButton]);

  // Hide nav on location change
  useEffect(() => {
    setMobileNavOpen(false);
  }, [location]);

  //useOnClickOutside(popoverPanel, () => setPopoverOpen(false));

  // const createIcon = (elm: any) => {
  //   return createElement(elm, { className: "w-8- h-8 text-orange-400" });
  // };

  return (
    <nav className="bg-brand-primary" ref={container}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-auto opacity-50"
                src="/logo_white.svg"
                alt="8Skills"
              />
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavLink
                  exact
                  to="/"
                  className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  activeClassName="bg-gray-900 text-white"
                >
                  {f({ id: "nav.home" })}
                </NavLink>
                <Menu as="div" className="relative inline-block text-left">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md inline-flex items-center text-sm font-medium">
                          {f({ id: "organizations.name" })}
                          <ChevronDownIcon
                            className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white outline-none divide-y divide-gray-100"
                        >
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  module="organization.organization"
                                  to="/org"
                                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                  <OfficeBuildingIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                  {f({ id: "organizations.name" })}
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              <NavLink
                                module="organization.units"
                                to="/units"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                              >
                                <CubeIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                {f({ id: "units.name" })}
                              </NavLink>
                            </Menu.Item>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              <NavLink
                                to="/orgchart"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                module="organization.organigram.show"
                              >
                                <ShareIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transform rotate-90" />
                                {f({ id: "orgchart.name" })}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item>
                              <NavLink
                                to="/revenues"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                module="organization.organigram.revenue_matrix"
                              >
                                <CashIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                Umsatzmatrix
                              </NavLink>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                <Menu as="div" className="relative inline-block text-left">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md inline-flex items-center text-sm font-medium">
                          {f({ id: "skills.name" })}
                          <ChevronDownIcon
                            className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white outline-none divide-y divide-gray-100"
                        >
                          <div className="py-1">
                            <Menu.Item>
                              <NavLink
                                to="/skillTypes"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                module="competencies.competencies_types"
                              >
                                <CollectionIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                {f({ id: "skillTypes.name" })}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item>
                              <NavLink
                                to="/skills"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                module="competencies.company.competencies"
                              >
                                <AcademicCapIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                {f({ id: "skills.name" })}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item>
                              <NavLink
                                to="/skillGroups"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                module="competencies.competency_groups"
                              >
                                <CollectionIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                {f({ id: "skillGroups.name" })}
                              </NavLink>
                            </Menu.Item>
                            <Menu.Item>
                              <NavLink
                                to="/skillScales"
                                module="competencies.competency_scaling"
                                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                              >
                                <AdjustmentsIcon className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                {f({ id: "skillScales.name" })}
                              </NavLink>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
                <NavLink
                  exact
                  to="/jobs"
                  className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  activeClassName="bg-gray-900 text-white"
                  module="jobs.show"
                >
                  {f({ id: "jobs.name" })}
                </NavLink>
                <NavLink
                  exact
                  to="/employees"
                  className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  activeClassName="bg-gray-900 text-white"
                  module="employees.show"
                >
                  {f({ id: "employees.name" })}
                </NavLink>
                <NavLink
                  exact
                  to="/users"
                  className="text-gray-50 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  activeClassName="bg-gray-900 text-white"
                  module="users.show"
                >
                  {f({ id: "users.name" })}
                </NavLink>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        ref={popoverButton}
                        className={`
                            ${open ? "" : "text-opacity-90"}
                            text-white group bg-gray-700 px-3 py-2 rounded-md inline-flex items-center text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span>{f({ id: "nav.more" })}</span>
                        <ChevronDownIcon
                          className={`${open ? "" : "text-opacity-70"}
                              ml-2 h-5 w-5 text-gray-300 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Transition
                        show={popoverOpen}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          ref={popoverPanel}
                          static
                          className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl"
                        >
                          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                              <NavLink
                                exact
                                to="/teams"
                                onClick={() => setPopoverOpen(false)}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                  <UserGroupIcon className="w-8- h-8 text-orange-400" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {f({ id: "teams.name" })}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {f({ id: "meta.description" })}
                                  </p>
                                </div>
                              </NavLink>
                              <NavLink
                                exact
                                to="/recruiting"
                                onClick={() => setPopoverOpen(false)}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                  <IdentificationIcon className="w-8- h-8 text-orange-400" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    Recruiting
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {f({ id: "meta.description" })}
                                  </p>
                                </div>
                              </NavLink>
                              <NavLink
                                exact
                                to="/seminars"
                                onClick={() => setPopoverOpen(false)}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                  <BookOpenIcon className="w-8- h-8 text-orange-400" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {f({ id: "seminars.name" })}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {f({ id: "meta.description" })}
                                  </p>
                                </div>
                              </NavLink>
                              <NavLink
                                exact
                                to="/reports"
                                onClick={() => setPopoverOpen(false)}
                                className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                                  <DocumentReportIcon className="w-8- h-8 text-orange-400" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-sm font-medium text-gray-900">
                                    {f({ id: "reports.name" })}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    {f({ id: "meta.description" })}
                                  </p>
                                </div>
                              </NavLink>
                            </div>
                            <div className="p-4 bg-gray-50">
                              {/* -> <a> */}
                              <span className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <span className="flex items-center">
                                  <span className="text-sm font-medium text-gray-900">
                                    {f({ id: "docs.name" })}
                                  </span>
                                </span>
                                <span className="block text-sm text-gray-500">
                                  {f({ id: "docs.description" })}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <Link to="/messages">
                <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span className="sr-only">Benachrichtigungen anzeigen</span>
                  <AtSymbolIcon className="h-6 w-6" />
                </button>
              </Link>

              <Menu as="div" className="ml-3 relative">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        aria-label="Menu"
                        className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      >
                        <span className="sr-only">Benutzermenu öffnen</span>
                        <UserCircleIcon className="w-8 h-8 text-gray-400" />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white outline-none divide-y divide-gray-100"
                      >
                        <div className="py-1">
                          <Menu.Item>
                            <NavLink
                              to="/users/settings"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              module="admin.permissions"
                              role="menuitem"
                            >
                              {f({ id: "user.settings" })}
                            </NavLink>
                          </Menu.Item>
                        </div>

                        <div className="py-1">
                          {dataLoggedIn?.isLoggedIn === false ? (
                            <Menu.Item>
                              <NavLink
                                to="/users/login"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                              >
                                {f({ id: "actions.login" })}
                              </NavLink>
                            </Menu.Item>
                          ) : (
                            <Menu.Item>
                              <NavLink
                                to="/users/logout"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                              >
                                {f({ id: "actions.logout" })}
                              </NavLink>
                            </Menu.Item>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu öffnen</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={mobileNavOpen ? "block md:hidden" : "hidden md:hidden"}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {navItems.map(({ navItem, navUrl }) => (
            <NavLink
              key={navUrl}
              to={navUrl}
              activeClassName="bg-gray-900 text-white"
              className="text-gray-50 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              {navItem}
            </NavLink>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-700">
          <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <UserCircleIcon />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium leading-none text-white">
                Benutzername
              </div>
              <div className="text-sm font-medium leading-none text-gray-400">
                Position
              </div>
            </div>
            <Link to="/messages">
              <button className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">Benachrichtigungen anzeigen</span>
                <AtSymbolIcon className="h-6 w-6" />
              </button>
            </Link>
          </div>
          <div className="mt-3 px-2 space-y-1">
            <NavLink
              to="/users/settings"
              module="admin.permissions"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              {f({ id: "user.settings" })}
            </NavLink>

            <NavLink
              to="/users/login"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              Login
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
